import { useTheme, styled } from "@mui/material/styles";
import { Autocomplete, Box, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";
import { SearchOutlined, CloseOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { VariableSizeList } from "react-window";
import PropTypes from "prop-types";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";

// project import
import store from "../../store/store";
import { getFullContact } from "../../store/reducers/contactsSlice";
import { openEditContactDialog } from "../../store/reducers/dialogSlice";
import { isSubscriptionOver, isTrialOver } from "../../services/subscriptionStatus";
import { UserAvatar } from "../UserAvatar";
import countries from '../../countries.json';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
/* eslint-disable-next-line */
const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
) {
    const { children, ...other } = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 40 : 48;

    const getChildSize = (child) => {
        /* eslint-disable-next-line */
        if (child.hasOwnProperty("group")) {
            return 48;
        }
        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    children: PropTypes.node,
};

const StyledPopper = styled(Popper)(({ theme }) => ({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0,
        },
    },
    minWidth: 480,
    width: "auto",
}));

const CustomPopper = (props) => {
    const { anchorEl, style, ...other } = props;
    const [width, setWidth] = useState(480);

    useEffect(() => {
        if (anchorEl) {
            const anchorWidth = anchorEl.getBoundingClientRect().width;
            setWidth(Math.max(anchorWidth, 480));
        }
    }, [anchorEl]);

    return (
        <StyledPopper
            {...other}
            anchorEl={anchorEl}
            style={{
                width: `${width}px`,
                minWidth: "480px",
                ...style,
            }}
        />
    );
};

export default function SearchContacts() {
    const useFindPath = () => {
        const location = useLocation();
        const [currentPath, setCurrentPath] = useState();
        useEffect(() => {
            setCurrentPath(location.pathname);
        }, [location]);
        return currentPath;
    };
    const currentPath = useFindPath();
    return currentPath && currentPath === '/' && <AutocompleteStyled />;
}



function AutocompleteStyled() {
    const subscription = useSelector((state) => state.user.subscription);
    let contacts = useSelector((state) => state.contacts.fullContactsPersons);
    const [sortedContacts, setSortedContacts] = useState([]);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (contacts && contacts.length > 0) {
            const sortedContacts = sortContacts(contacts);
            setSortedContacts(sortedContacts);
        }
    }, [contacts]);

    const [inputValue, setInputValue] = React.useState("");

    if (isTrialOver(subscription) || isSubscriptionOver(subscription)) return null;

    contacts = contacts.map(item => ({ ...item, type: 'Contacts' }));
    const filterOptions = (options, { inputValue }) => {
        const startTime = performance.now();
        const users = options.filter(user => searchInObject(user, inputValue, countries));
        const endTime = performance.now();
        console.log(`Time taken: ${endTime - startTime} milliseconds`);
        return users;
    }

    function searchInObject(obj, searchTerm, countries) {
        const lowerSearchTerm = searchTerm.toLowerCase();
        return Object.entries(obj).some(([key, value]) => {
            if (key === 'countryCode' && typeof value === 'string') {
                // Find all countries whose name contains the search query
                const matchedCountries = countries.filter(countryObj =>
                    countryObj.country?.name?.toLowerCase().includes(lowerSearchTerm)
                );
                // Check if the countryCode value matches the shortCode of any found country
                return matchedCountries.some(countryObj =>
                    countryObj.country?.shortCode?.toLowerCase() === value.toLowerCase()
                );
            }
            // If the value is an object or an array, call the function recursively
            if (value && typeof value === 'object') {
                return searchInObject(value, searchTerm, countries);
            }
            // Check if the string contains the search term
            return typeof value === 'string' && value.toLowerCase().includes(lowerSearchTerm);
        });
    }

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        setOpen(newInputValue.length > 0);
    };

    const onChange = (event, user) => {
        store.dispatch(getFullContact(user.resourceName));
        store.dispatch(openEditContactDialog(true));
    };

    return (
        <Stack sx={{
            width: '100%',
            maxWidth: 720,
            minWidth: 480,
            height: 48,
            justifyContent: 'center',
        }}>
            <Autocomplete
                open={open}
                onClose={() => setOpen(false)}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                onChange={onChange}
                freeSolo
                disableClearable
                autoHighlight
                options={sortedContacts}
                getOptionLabel={(option) => option.name?.displayName || option?.emailAddresses?.[0]?.value || ''}
                filterOptions={filterOptions}
                renderInput={(params) => {
                    const { InputLabelProps, InputProps, ...rest } = params;
                    return <StyledTextField
                        sx={{
                            width: '100%',
                            height: 42,
                            display: "flex",
                            justifyContent: "center",
                        }}
                        id="input-with-icon-textfield"
                        placeholder="Search"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start" sx={{ marginLeft: '6px', }}>
                                    <SearchOutlined sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                inputValue && <InputAdornment position="end">
                                    <CloseOutlined
                                        sx={{
                                            width: 20,
                                            height: 20,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            setInputValue('');
                                        }}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                height: '42px',
                                width: '100%',
                                color: 'rgba(32, 33, 36, 1)',
                                fontSize: '16px',
                                fontFamily: 'Open Sans',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '46px',
                                letterSpacing: '-0.32px',
                            },
                        }}
                        {...rest}
                    />
                }}
                renderOption={(props, option, state) => {
                    const photo = option?.photos?.[0]?.url || option?.photos?.[1]?.url || null;
                    return (<Box component="li" sx={{ '& > img': { flexShrink: 0 } }} {...props}
                        key={state.index}>
                        <Stack direction={'row'} spacing={2} alignItems="center" justifyContent="center">
                            <UserAvatar alt={option.name?.displayName || option?.emailAddresses?.[0]?.value} sx={{ width: 28, height: 28 }} src={photo} />
                            <Stack direction={'row'} spacing={'10.5px'}>
                                <Typography noWrap variant={'settingsMenu'}>{option.name?.displayName || option?.emailAddresses?.[0]?.value}</Typography>
                                <EmailSection option={option} />
                            </Stack>
                        </Stack>
                    </Box>)
                }}
                ListboxComponent={ListboxComponent}
                PopperComponent={(props) => <CustomPopper {...props} />}
            />
        </Stack>
    );
}

const EmailSection = ({ option }) => {
    if (!option?.emailAddresses?.[0]?.value) return null;
    return (
        <>
            <Typography variant={'settingsMenu'} color={'rgba(32, 33, 36, 0.54)'}>&bull;</Typography>
            <Typography variant={'settingsMenu'} color={'rgba(32, 33, 36, 0.54)'}>{option?.emailAddresses?.[0]?.value}</Typography>
        </>
    )
}

const StyledTextField = styled(TextField)(() => ({
    borderRadius: 8,
    backgroundColor: '#F1F3F4',
    '.MuiInput-root': {
        height: '42px',
    },
    '& label.Mui-focused': {
        color: '#A0AAB4',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#F1F3F4',
            borderRadius: 8,
        },
        '&:hover fieldset': {
            borderColor: '#F1F3F4',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#F1F3F4',
        },
    },
}));

const sortContacts = (contacts) => {
    // Create a Set for unique identifiers (name or email)
    const uniqueIdentifiers = new Set(
        contacts.map(contact => (contact.name?.displayName || contact?.emailAddresses?.[0]?.value || '').toLowerCase()
        )
    );
    // Convert Set to sorted array
    const sortedIdentifiers = Array.from(uniqueIdentifiers).sort();
    // Create a Map for quick search of contacts
    const contactsMap = new Map();
    contacts.forEach(contact => {
        const identifier = (contact.name?.displayName || contact?.emailAddresses?.[0]?.value || '').toLowerCase();
        if (!contactsMap.has(identifier)) {
            contactsMap.set(identifier, []);
        }
        contactsMap.get(identifier).push(contact);
    });
    // Collect sorted result
    return sortedIdentifiers.flatMap(id => contactsMap.get(id));
};
