import { FormControl, MenuItem, Select } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { useState } from "react";

import store from "../../store/store";
import { changeUserRole } from "../../store/reducers/groupsSlice";

export default function PermissionsSelectForOneUser({ user, isViewPermission }) {
    const groupsToShare = useSelector((state) => state.groups.groupsToShare);
    const [permission, setPermission] = useState('');
    const userShareInfo = groupsToShare[0].share.find(item => item.email === user.email) || groupsToShare[0].domains_groups_share.find(item => item.email === user.email);
    const handleChange = async (event) => {
        setPermission(event.target.value);
        const data = {
            group: groupsToShare[0].resource,
            userId: userShareInfo.user_id,
            email: userShareInfo.email,
            role: event.target.value,
        }
        store.dispatch(changeUserRole(data));
    };
    if (user.role === 'owner') {
        return <Typography
            sx={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: 14,
                fontFamily: "Roboto",
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.2px',
            }}
        >
            Owner
        </Typography>
    }
    const getRoleText = (user) => {
        switch (user.role) {
            case 'VIEW':
                return 'View only';
            case 'EDIT':
                return 'Can edit';
            case 'SHARE':
                return 'Can reshare';
            default:
                return 'View only';
        }
    };
    const roleText = getRoleText(user);
    if (isViewPermission) {
        return <Typography
            sx={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: 14,
                fontFamily: "Roboto",
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.2px',
            }}
        >
            {roleText}
        </Typography>
    }
    return (
        <FormControl variant="standard" fullWidth>
            <Select
                // disabled={user.role === 'owner'}
                disableUnderline
                sx={{
                    color: 'rgba(0, 0, 0, 1)',
                    fontSize: 14,
                    fontFamily: "Roboto",
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    letterSpacing: '0.2px',
                    backgroundColor: 'inherit',
                    '& .MuiSelect-select': {
                        backgroundColor: 'inherit',
                    },
                    '&.Mui-focused .MuiSelect-select': {
                        backgroundColor: 'inherit',
                    },
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            '& .MuiMenuItem-root': {
                                color: 'rgba(0, 0, 0, 1)',
                                fontSize: '16px',
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                            },
                        },
                    },
                }}
                labelId="demo-simple-select-label"
                id="permission-select"
                value={permission || user.role}
                label="Permission"
                onChange={handleChange}
            >
                <MenuItem value={'VIEW'}>View only</MenuItem>
                <MenuItem value={'EDIT'}>Can edit</MenuItem>
                <MenuItem value={'SHARE'}>Can reshare</MenuItem>
            </Select>
        </FormControl>
    );
}
