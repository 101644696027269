const serverApi = (endpoint, data = null, method = 'GET') => {
    const host = process.env.REACT_APP_API_URL || 'https://app2.sharedcontactsmanager.com/';
    const url = new URL(`/${endpoint}`, host);
    return fetch(
        url,
        {
            method,
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: data ? JSON.stringify(data) : undefined
        },
    )
}

export default serverApi;