import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { Fragment } from "react";
import { Divider, IconButton, Menu, MenuItem, Typography, Stack } from "@mui/material";
import { useSelector } from "react-redux";

// project import
import store from "../../store/store";
import { logout } from "../../store/reducers/userSlice";
import { UserAvatar } from "../UserAvatar";

export default function ProfileMenu() {
    const user = useSelector((state) => state.user.user);
    const handleClickLogout = async (popupState) => {
        popupState.close();
        await store.dispatch(logout());
        window.location = process.env.REACT_APP_MAIN_SITE_URL;
    };
    return (
        <PopupState variant="popover" popupId="profile-popup-menu" disableAutoFocus={false}>
            {(popupState) => (
                <Fragment key="profile_menu">
                    <IconButton
                        aria-label='profile'
                        color='default'
                        sx={{ width: 32, height: 32 }}
                        {...bindTrigger(popupState)}
                    >
                        <UserAvatar
                            isProfileAvatar
                            alt={user.name || user.email}
                            sx={{ width: 32, height: 32 }}
                            src={user.photo || null} />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                        <MenuItem
                            disableRipple
                            sx={[
                                {
                                    '&:hover': { backgroundColor: 'transparent' },
                                    cursor: 'default',
                                    userSelect: 'text', // Allows text selection
                                },
                            ]}
                        >
                            <Stack spacing={0}>
                                <Typography variant={'settingsMenu'}>{user.name}</Typography>
                                <Typography
                                    variant={'label'}
                                    sx={{
                                        color: 'rgba(0, 0, 0, 0.60)',
                                        letterSpacing: '0.15px',
                                        userSelect: 'text', // Allows text selection
                                    }}
                                >
                                    {user.email}
                                </Typography>
                            </Stack>
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => {
                            handleClickLogout(popupState);
                        }}>
                            <Typography variant={'settingsMenu'}>Log out</Typography>
                        </MenuItem>
                    </Menu>
                </Fragment>
            )}
        </PopupState>
    );
}
