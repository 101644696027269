import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';



// project import
import dataReducer from './reducers/dataSlice';
import dialogReducer from './reducers/dialogSlice';
import groupsReducer from './reducers/groupsSlice';
import contactsReducer from './reducers/contactsSlice';
import userReducer from './reducers/userSlice';
import notificationReducer from './reducers/notificationSlice';
import rootSaga from "./sagas";
import ignorePreviousRequestMiddleware from "../api/ignorePreviousRequestMiddleware";
import { axiosMiddleware } from "../api/middleware";

const sagaMiddleware = createSagaMiddleware();

export default configureStore({
    devTools: true,
    reducer: {
        data: dataReducer,
        groups: groupsReducer,
        contacts: contactsReducer,
        dialogsOpen: dialogReducer,
        user: userReducer,
        notification: notificationReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ thunk: true })
            .concat(ignorePreviousRequestMiddleware)
            .concat(axiosMiddleware)
            .concat(sagaMiddleware)
})

sagaMiddleware.run(rootSaga)
