import { SvgIcon } from "@mui/material";
import * as React from "react";

const ShareLabelIcon = ({ isShareDisabled }) => {
    return isShareDisabled ? <SvgIcon sx={{ width: 15, height: 24 }}>
        <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M3.30672 2.5L10.9967 6.37031L10.999 6.36914V6.37149V9.46633V9.46867L10.9967 9.4675L0.999023 14.4993V11.4021L7.91977 7.91891L3.30672 5.59719V2.5ZM4.00008 17.6289L11.6923 21.5V18.4031L7.077 16.0804L14 12.5964V9.49945L4.00008 14.5319L4 14.5319V14.532V17.6288V17.6289L4.00008 17.6289Z"
                fill="black" fillOpacity="0.32" />
        </svg>
    </SvgIcon>
        : <SvgIcon sx={{ width: 15, height: 24 }}>
            <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 6.37012L1 11.403V14.5002L11 9.46731V6.37012Z" fill="white" />
                <path d="M11 6.37149L3.30769 2.5V5.59719L11 9.46867V6.37149Z" fill="white" />
                <path d="M4 17.6288L11.6923 21.5V18.4031L4 14.5319V17.6288Z" fill="white" />
                <path d="M4 17.6299L14 12.5974V9.50042L4 14.5329V17.6299Z" fill="white" />
            </svg>
        </SvgIcon>
}

export {
    ShareLabelIcon,
}
