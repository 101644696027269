import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { Tooltip, Chip, Typography, Box } from '@mui/material';

// project import
import { changeContacts } from "../../store/reducers/groupsSlice";

const Labels = ({ filteredGroups }) => {
    const dispatch = useDispatch();
    const [isOverflowed, setIsOverflowed] = useState([]);
    const refs = useRef([]);
    let tooltipTextArray;
    if (filteredGroups.length > 3) {
        tooltipTextArray = filteredGroups.slice(2);
    }
    useEffect(() => {
        const checkOverflow = () => {
            const overflowStates = refs.current.map((el) => {
                if (!el) return false; // If the element has not been rendered yet
                return el.scrollWidth > el.offsetWidth;
            });
            setIsOverflowed(overflowStates);
        };
        // Postpone the check via requestAnimationFrame
        requestAnimationFrame(checkOverflow);
    }, [filteredGroups]);

    return filteredGroups.slice(0, 3).map((item, index) => {
        const chipContent = (
            <Typography
                sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block',
                    maxWidth: 132
                }}
                variant={'label'}
                ref={(el) => {
                    refs.current[index] = el;
                }}
            >
                {index === 2 && filteredGroups.length > 3
                    ? `+${filteredGroups.length - 2}`
                    : item.name}
            </Typography >
        );

        const chip = (
            <Chip
                clickable={!(index === 2 && filteredGroups.length > 3)}
                key={index}
                onClick={(event) => {
                    event.stopPropagation();
                    if (index === 2 && filteredGroups.length > 3) return null;
                    return dispatch(changeContacts({ resource: item.resource }));
                }}
                sx={{
                    marginRight: 1,
                    maxWidth: 132,
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    borderRadius: '8px',
                    cursor: index === 2 && filteredGroups.length > 3 ? 'default' : 'pointer',
                }}
                label={chipContent}
                size="medium"
                variant={'outlined'}
                color={'default'}
            />
        );

        return (
            <Tooltip
                key={index}
                // disableHoverListener={!isOverflowed[index]} // Disable Tooltip if text is not truncated
                title={
                    (index === 2 && filteredGroups.length > 3)
                        ? <Box sx={{ maxWidth: 300 }}>
                            {tooltipTextArray.map((filteredGroup, idx) => (
                                <span
                                    key={idx}
                                    style={{
                                        display: 'block',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    <Box
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            return dispatch(changeContacts({ resource: filteredGroup.resource }));
                                        }}
                                        sx={{
                                            cursor: 'pointer',
                                            color: 'inherit',
                                            textDecoration: 'underline',
                                            '&:hover': {
                                                textDecoration: 'none',
                                                color: 'rgba(255, 255, 255, 0.5)',
                                            },
                                        }}
                                    >
                                        {filteredGroup.name}
                                    </Box>
                                </span>
                            ))}
                        </Box>
                        : isOverflowed[index] ? item.name : ''
                }
            >
                {chip}
            </Tooltip>
        );
    });
};

export default Labels;
