import { useEffect } from "react";
import { CssBaseline } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// project import
import ThemeProvider from './theme';
import Router from "./routes";
import NotificationBar from "./components/Alerts/NotificationBar";
import sockets from "./api/sockets";
import { setSelectedContacts } from "./store/reducers/contactsSlice";
import useAnalytics from "./api/useAnalytics";
import GoogleSignIn from "./components/googleSignIn";

export default function App() {
    const dispatch = useDispatch();
    const needLogin = useSelector((state) => state.user.needLogin);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const contacts = useSelector((state) => state.contacts.contacts);
    const user = useSelector((state) => state.user);

    useEffect(() => {
        let socket;
        async function openSockets() {
            try {
                if (!user.userInitialized || user.needLogin) return;
                socket = sockets(user.user.socket_token);
            } catch (error) {
                console.error('Error opening sockets:', error);
            }
        }
        openSockets();
        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [user.userInitialized]);

    useEffect(() => {
        async function updateSelectedContacts() {
            if (currentGroup) {
                const resourceSet = new Set(currentGroup.contacts_resources);
                const filteredContacts = contacts.filter((user) => resourceSet.has(user.id));
                dispatch(setSelectedContacts({ selectedContacts: filteredContacts }));
            }
        }

        updateSelectedContacts();
    }, [currentGroup, contacts, dispatch]);

    useAnalytics(user);

    if (needLogin) {
        return (
            <ThemeProvider>
                <CssBaseline />
                <GoogleSignIn />
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider>
            <CssBaseline />
            <NotificationBar />
            <Router />
        </ThemeProvider>
    );
}
