import { Alert, Box, Typography } from "@mui/material";

const ViewPermissionAlert = ({ isViewPermission }) => {
    if (!isViewPermission) return null;
    return <Alert severity="info"
        sx={{
            height: 48,
            background: '#E6F3FA',
            '& .MuiAlert-icon': {
                color: 'rgba(59, 125, 237, 1)'
            }
        }}>
        <Typography
            sx={{
                color: "#013654",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                textAlign: "center",
                letterSpacing: "0.17px",
            }}
        >
            With your <Box component="span" sx={{ fontWeight: 500 }}>Viewer</Box> permissions, you can't modify or remove other people's access to this label.
        </Typography>
    </Alert>
};

export default ViewPermissionAlert;
